((d) => {
  var currentScript =
    document.currentScript ||
    (() => {
      var scripts = d.getElementById("connexease-crm-js-sdk");
      return scripts;
    })();

  if (!currentScript) {
    if (console && console.error) {
      console.error(
        'Unable to locate the current script. Please ensure it has an "id" attribute with the value "connexease-crm-js-sdk".'
      );
    }
    return;
  }

  var provider = currentScript.getAttribute("data-provider");
  var projectId = currentScript.getAttribute("data-project-id");

  if (!provider || !projectId) {
    if (console && console.error) {
      console.error(
        "Missing required data attributes: data-provider or data-project-id."
      );
    }
    return;
  }

  var script = d.createElement("script");
  script.type = "text/javascript";
  script.async = true;

  script.onload = () => {
    if (!window.setInterval) {
      window.setInterval = (fn, delay) => {
        var id = window.setTimeout(function tick() {
          fn();
          id = window.setTimeout(tick, delay);
        }, delay);
        return id;
      };
    }

    var isModuleDefinedOnWindow = () =>
      window.RegisteredConnexeaseModules &&
      window.RegisteredConnexeaseModules.ConnexeaseCRM;

    var maxRetryCount = 5;
    var retryCount = 0;

    var interval = window.setInterval(() => {
      if (isModuleDefinedOnWindow()) {
        clearInterval(interval);
        var ConnexeaseCRM = window.RegisteredConnexeaseModules.ConnexeaseCRM;
        try {
          new ConnexeaseCRM(projectId).watch();
        } catch (e) {
          if (console && console.error) {
            console.error("Error initializing ConnexeaseCRM:", e);
          }
        }
      } else {
        retryCount++;
        if (console && console.log) {
          console.log(
            "ConnexeaseCRM module is not defined yet. Retry count:",
            retryCount
          );
        }
        if (retryCount >= maxRetryCount) {
          if (console && console.error) {
            console.error(
              "ConnexeaseCRM module is not defined after retrying",
              maxRetryCount,
              "times."
            );
          }
          clearInterval(interval);
        }
      }
    }, 1000 * retryCount);
  };

  script.onerror = () => {
    if (console && console.error) {
      console.error("Failed to load script:", script.src);
    }
  };

  var src = null;

  if (provider === "akinon") {
    src = "https://crm-cdn.connexease.com/akinon/script.js";
  } else if (provider === "tsoft") {
    src = "https://crm-cdn.connexease.com/tsoft/script.js";
  } else if (provider === "ticimax") {
    src = "https://crm-cdn.connexease.com/ticimax/script.js";
  } else if (provider === "ikas") {
    src = "https://crm-cdn.connexease.com/ikas/script.js";
  }
  if (!src) {
    if (console && console.error) {
      console.error(
        "Unknown provider:",
        provider,
        '. Supported providers are "akinon", "tsoft", and "ticimax".'
      );
    }
    return;
  }

  script.src = src;

  var head = d.getElementsByTagName("head")[0];
  if (head) {
    head.appendChild(script);
  }
})(document);
